<template>
  <div>
    <BrAppBar class="no-print"/>
    <BrAside />

    <v-main class="container-gestao">
      <div class="wrapper">
        <breadcrumbs />
        <v-container fluid>
          <!-- <v-breadcrumbs :items="items" :divider="divider" class="breadcrumbs">
            <template v-slot:divider>
              >
            </template>
          </v-breadcrumbs> -->
          <!-- <BrTagsView /> -->
          <transition name="fade-transform" mode="out-in" appear>
            <router-view/>
          </transition>
        </v-container>
      </div>
      <BrFooter />
    </v-main>
  </div>
</template>

<script>


export default {
  name: 'Init',
  components: {
    BrAppBar: () => import('@/views/layout/appBar'),
    BrAside: () => import('@/views/layout/aside'),
    BrFooter: () => import('@/views/layout/footer'),
    // BrTagsView: () => import('@/views/components/tagsView'),
    breadcrumbs: () => import('@/views/layout/breadcrumbs')
  },
  data: () => ({
    items: [
      {
        text: 'home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Cadastro de usuário',
        disabled: true
      }
    ],
    divider: '>'
  }),

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../../assets/styles/helpers/shareds';
  @import '@/assets/styles/init';
</style>
